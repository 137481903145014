<template>
    <button class="btn btn-lime fs-6 position-relative"><span><slot/></span></button>
</template>

<script>
    export default {
        name: "ButtonLime"
    }
</script>

<style scoped>
    .btn-lime {
        padding:.75rem 3rem;
        border-radius: 2.5em !important;
        background-color: #d8f220;
        font-weight: bolder;
        transition: .2s all ease-in-out;
        box-shadow: 0rem 1rem 1rem #c9f79d;
        overflow: hidden;
    }
    .btn-lime span {
        position: relative;
    }

    .btn-lime:before {
        content: '';
        position: absolute;
        background-image: linear-gradient( -45deg , #d8f220 20%, #74ea00 80%);
        height: 100%;
        width: 100%;
        top: 0;
        left:0;
        z-index: 0;
        opacity: 1;
        transition: opacity .3s ease-in-out;
    }

    .btn-lime:hover, .btn-lime:focus, .btn-lime:active{
        box-shadow: 0rem 0rem 0rem #c9f79d;
    }

    .btn-lime:hover:before, .btn-lime:focus:before, .btn-lime:active:before{
        opacity: 0;
    }
</style>