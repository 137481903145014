<template>
  <kinesis-container v-if="page === 1"  @wheel="page = 2">
    <section class="container-fluid py-5 pl pr vh-100 overflow-lg-hidden home-container">
      <div class="row py-xxl-5 py-lg-4 py-3 h-100 d-md-flex">
        <div class="col-xxl-3 col-xl-4 col-lg-5 h-lg-100 h-auto d-flex flex-column justify-content-center position-relative">
          <div>
            <p class="fs-2 sub-title fw-bolder mb-xl-5 pb-xl-5 mb-3 pb-3 lh-sm"  data-aos="fade-right" data-aos-delay="50">Заявите о себе<br>с командой <span class="lime-text">LimeСlub</span></p>
            <p class="fs-2 mb-3 lh-1 fw-light"  data-aos="fade-right" data-aos-delay="100">Увеличим эффективность<br>бизнеса в 5 раз:</p>
            <p class="fs-2 mb-xl-5 pb-xl-3 mb-3 lh-1"  data-aos="fade-right" data-aos-delay="150">от анализа и стратегии<br>до реализации </p>
            <ButtonLime data-aos="fade-right"  data-aos-delay="200">Заказать ПРОЕКТ</ButtonLime>
          </div>

        </div>
        <div class="col-xxl-9 col-xl-8 col-lg-7 h-lg-100 h-auto d-flex flex-column justify-content-center position-relative">
          <div class="text-center position-relative"  data-aos="zoom-in"  data-aos-delay="350">
            <div  class="position-absolute" style="bottom: 15%!important;right:20%; width: 35%">
            <kinesis-element :strength="10" class="w-100">
              <img src="../assets/lime_element_2.png" class="w-100"/>
            </kinesis-element>
            </div>
            <div  class="position-absolute" style="top: 0%!important;left:30%; width: 20%">
              <kinesis-element :strength="15" class="w-100">
                <span class="home-container__title lh-1 text-center">Marketing</span>
              </kinesis-element>
            </div>
            <div  class="position-absolute" style="top: 0%!important;left:15%; width: 20%">
              <kinesis-element :strength="-35" class="w-100">
                <img src="../assets/board.png" class="w-100"/>
              </kinesis-element>
            </div>
            <div  class="position-absolute" style="top: 20%!important;left:10%; width: 12%">
              <kinesis-element :strength="30" class="w-100">
                <img src="../assets/darts.png" class="w-100"/>
              </kinesis-element>
            </div>
            <kinesis-element :strength="20">
              <img src="../assets/ipad.png" class="position-relative" style="width:50%; padding-top:10%;"/>
            </kinesis-element>
            <div  class="position-absolute" style="top: 35%!important;right:30%; width: 15%">
              <kinesis-element :strength="-10"  class="w-100">
                <img src="../assets/lupa.png"  class="w-100"/>
              </kinesis-element>
            </div>
          </div>
        </div>
      </div>
    </section>

  </kinesis-container>
  <kinesis-container v-if="page === 2" @wheel="page = 1">
    <section class="container-fluid py-5 pl pr vh-100 overflow-lg-hidden home-container">
      <div class="row py-xxl-5 py-lg-4 py-3 h-100 d-md-flex">
        <div class="col-xxl-3 col-xl-4 col-lg-5 h-lg-100 h-auto d-flex flex-column justify-content-center position-relative">
          <div>
            <p class="fs-2 sub-title fw-bolder mb-xl-5 pb-xl-5 mb-3 pb-3 lh-sm"  data-aos="fade-right" data-aos-delay="50">Заявите о себе<br>с командой <span class="lime-text">LimeСlub</span></p>
            <p class="fs-2 mb-3 lh-1 fw-light"  data-aos="fade-right" data-aos-delay="100">Разработаем It-продукт и<br>предоставим бесплатную<br>техподдержку на 1 год:</p>
            <p class="fs-2 mb-xl-5 pb-xl-3 mb-3 lh-1"  data-aos="fade-right" data-aos-delay="150">От landing page до<br> программного обеспечения</p>
            <ButtonLime data-aos="fade-right"  data-aos-delay="200">Заказать ПРОЕКТ</ButtonLime>
          </div>

        </div>
        <div class="col-xxl-9 col-xl-8 col-lg-7 h-lg-100 h-auto d-flex flex-column justify-content-center position-relative">
          <div class="position-relative" data-aos="zoom-in" data-aos-delay="250">
            <div  class="position-absolute" style="bottom: 15%!important;right:20%; width: 35%">
              <kinesis-element :strength="10" class="w-100">
                <img src="../assets/lime_element_2.png" class="w-100"/>
              </kinesis-element>
            </div>
            <div  class="position-absolute" style="top: 0%!important;left:20%; width: 20%">
              <kinesis-element :strength="15" class="w-100">
                <span class="home-container__title lh-1 text-center">ITTechnology</span>
              </kinesis-element>
            </div>

            <kinesis-element :strength="20">
              <img src="../assets/monoblock.png" class="position-relative" style="width:45%; padding-top:5%; padding-left: 15%;"/>
            </kinesis-element>
            <div  class="position-absolute" style="bottom: -5%!important;left:10%; width: 12%">
              <kinesis-element :strength="-5" class="w-100">
                <img src="../assets/mouse.png" class="w-100"/>
              </kinesis-element>
            </div>
            <div  class="position-absolute" style="bottom: -5%!important;right:20%; width: 35%">
              <kinesis-element :strength="5"  class="w-100">
                <img src="../assets/klava.png"  class="w-100"/>
              </kinesis-element>
            </div>
          </div>
        </div>
      </div>
    </section>

  </kinesis-container>
</template>

<script>
  import ButtonLime from "../components/ButtonLime";
  export default {
    data(){
      return {page:1}
    },
    name: 'Home',
    components: {ButtonLime},
  }
</script>

<style lang="scss">

  .home-container {
    &__title{
      display: block;
      width: 100%;
      font-size:9.35rem;
      font-weight: 700;
      color: var(--lc-violet);
      @media (max-width:1440px) {
        font-size:6.875rem
      }
      @media (max-width:1200px) {
        font-size:4.875rem
      }
      @media (max-width:425px) {
        font-size:3rem
      }

      @media (max-width:375px) {
        font-size:2rem
      }

    }
  }
  .home-container:before {
    content: '';
    position: absolute;
    height: 100vh;
    width: 75%;
    background: var(--lc-grey);
    top: -10%;
    right: 0%;
  }
</style>
