<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top" :class="this.$route.path === '/contacts' ? 'bg-lc-grey' : 'bg-white'">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Переключатель навигации">
        <span class="navbar-toggler-icon"></span>
      </button>
      <router-link to="/" class="navbar-brand me-lg-3 me-0" >
        <img src="./assets/main-logo.png" width="157" height="32" alt="Логотип Limeclub.kz"/>
      </router-link>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav py-lg-0 py-2">
          <li class="nav-item">
            <router-link to="/" class="nav-link" @mouseup="hideMenu()"><span>Главная</span></router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link" @mouseup="hideMenu()"><span>О нас</span></router-link>
          </li>
          <li class="nav-item">
            <router-link to="/design" class="nav-link" @mouseup="hideMenu()"><span>Дизайн</span></router-link>
          </li>
          <li class="nav-item">
            <router-link to="/marketing" class="nav-link" @mouseup="hideMenu()"><span>Маркетинг</span></router-link>
          </li>
          <li class="nav-item">
            <router-link to="/it-development" class="nav-link" @mouseup="hideMenu()"><span>IT Разработка</span></router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contacts" class="nav-link" @mouseup="hideMenu()"><span>Контакты</span></router-link>
          </li>
        </ul>
      </div>
      <div class="d-lg-flex d-none">
        <a href="tel:+7 777 777 77 77" class="btn fw-bolder text-reset shadow-none">+7 777 777 77 77</a>
        <button class="btn btn-outline-gradient bg-white d-flex position-relative border-3 border-transparent">
          Заказать звонок
        </button>
      </div>
    </div>
  </nav>
  <main>
    <router-view/>
  </main>
  <TheFooter v-if="this.$route.path !== '/'"></TheFooter>
</template>

<style lang="scss">
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>

<style>
  .navbar.navbar-expand-lg {
    box-shadow: 0 2px 15px var(--lc-grey);
  }
  .nav-link {
    position: relative;
    transition: all 0.2s ease-in-out;
  }

  .nav-link span {
    position: relative;
    z-index: 1;
  }

  .nav-link:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    background-color: #d8f220;
    background-image: linear-gradient(-35deg, #d8f220, #74ea00);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    z-index: 0;
  }

  .nav-link.router-link-exact-active:after,
  .nav-link:hover:after {
    opacity: 1;
  }

  @media (max-width: 991px) {
    .nav-link.router-link-exact-active,
    .nav-link:hover {
      padding-left: 0.5rem;
    }

    .nav-link:after {
      bottom: auto;
      left: auto;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      height: 100%;
      width: 100%;
      border-radius: 0;
    }

    .nav-link.router-link-exact-active:after,
    .nav-link:hover:after {
      opacity: 0.7;
    }
  }
</style>
<script>
  import TheFooter from "./components/TheFooter";
  export default {
    components: {TheFooter},
    methods:{
      hideMenu:function(){
        let menu = document.querySelector('#navbarNav');
        menu.classList.remove('show');
      }
    }
  }
</script>
