import { createApp } from 'vue'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
// import 'animate.css';
import AOS from 'aos';

import VueKinesis from "vue-kinesis";
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import './assets/fonts/stylesheet.css';
import './assets/css/site.css';

import router from './router';

createApp(App).use(router).use(VueKinesis).mount('#app')
AOS.init();
