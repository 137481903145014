<template>
    <footer class="pl pr">
        <div class="row py-4">
            <div class="col-3">
                @2020
            </div>
            <div class="col-9 text-end address">
                Адрес: г. Алматы, ул. Жамбыла 114, оф. 220 Телефон: +7 777 777 77 77
            </div>
        </div>
    </footer>

</template>

<script>
    export default {
        name: "TheFooter",
        props: {
            msg: String
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    footer {
        color: #414141;
        background-color: #f4f4f4;
    }

    .address {
        font-weight: 500;
    }
</style>
